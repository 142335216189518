.nav {
  > li {
    > a:hover,
    > a:focus {
      background-color: transparent;
    }
  }
}
.navbar {
  border: $none;
  font-size: $font-size-navbar;
  border-radius: 0;

  .navbar-minimize {
    float: left;
    margin: 3px 15px 3px 0;
  }

  .navbar-brand {
    font-weight: $font-weight-normal;
    margin: $navbar-margin-brand;
    padding: $navbar-padding-brand;
   /* font-size: $font-size-large-navbar; */
    font-size: large !important;
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .navbar-nav {
    > li > a {
      padding: $navbar-padding-a;
      margin: $navbar-margin-a;
      position: relative;
    }
    > li > a.btn {
      margin: $navbar-margin-a-btn;
      padding: $padding-base-vertical $padding-base-horizontal;
    }
    > li > a.btn-round {
      margin: $navbar-margin-a-btn-round;
    }
    > li > a [class^="fa"] {
      font-size: $font-size-medium;
      position: relative;
      line-height: 16px;
    }

    p {
      display: inline-block;
      padding-left: 5px;
      margin-bottom: 0;
    }

    .notification {
      position: absolute;
      background-color: #fb404b;
      text-align: center;
      border-radius: 10px;
      min-width: 18px;
      padding: 0 5px;
      height: 18px;
      font-size: 12px;
      color: #ffffff;
      font-weight: bold;
      line-height: 18px;
      top: 2px;
      left: 7px;
    }
  }
  .btn {
    margin: $navbar-margin-btn;
    font-size: $font-size-base;
  }
  .btn-simple {
    font-size: $font-size-medium;
  }
  .caret {
    // @include center-item();
  }

  &.fixed {
    width: calc(100% - 220px);
    right: 0;
    left: auto;
    border-radius: 0;
  }

  &.navbar-absolute {
    position: absolute;
    width: 100%;
    z-index: 1030;
  }
}

.main-panel .navbar-fixed {
  position: fixed;
  width: calc(100% - 220px);
  right: 0;
  z-index: 1031;
}

.navbar-nav > li > .dropdown-menu {
  border-radius: $border-radius-extreme;
  margin-top: -5px;
}

.navbar-transparent,
[class*="navbar-ct"] {
  .navbar-brand {
    color: $white-color;
    @include opacity(0.9);

    &:focus,
    &:hover {
      background-color: transparent;
      @include opacity(1);
    }
  }

  .navbar-nav {
    > li > a:not(.btn) {
      color: $white-color;
      border-color: $white-color;
      @include opacity(0.8);
    }
    > .active > a:not(.btn),
    > .active > a:hover:not(.btn),
    > .active > a:focus:not(.btn),
    > li > a:hover:not(.btn),
    > li > a:focus:not(.btn) {
      background-color: transparent;
      border-radius: 3px;
      color: $white-color;
      @include opacity(1);
    }
    .nav > li > a.btn:hover {
      background-color: transparent;
    }

    > .dropdown > a .caret,
    > .dropdown > a:hover .caret,
    > .dropdown > a:focus .caret {
      border-bottom-color: $white-color;
      border-top-color: $white-color;
    }

    > .open > a,
    > .open > a:hover,
    > .open > a:focus {
      background-color: transparent;
      color: $white-color;
      @include opacity(1);
    }
  }

  .btn-default {
    color: $white-color;
    border-color: $white-color;
  }
  .btn-default.btn-fill {
    color: $dark-gray;
    background-color: $white-color;
    @include opacity(0.9);
  }
  .btn-default.btn-fill:hover,
  .btn-default.btn-fill:focus,
  .btn-default.btn-fill:active,
  .btn-default.btn-fill.active,
  .open .dropdown-toggle.btn-fill.btn-default {
    border-color: $white-color;
    @include opacity(1);
  }
}
.navbar-transparent {
  .dropdown-menu .divider {
    background-color: rgba($white-color, 0.2);
  }
}

.nav-open .nav .caret {
  border-bottom-color: $white-color;
  border-top-color: $white-color;
}

.navbar-default {
 /* background-color: $white-navbar; */
  background-color: rgba(0, 0, 0, 0.984) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position:sticky;
  top: 0;
  z-index: 1000;

  .navbar-nav {
    > li > a:not(.btn) {
      color: $dark-gray;
    }

    > .active > a,
    > .active > a:not(.btn):hover,
    > .active > a:not(.btn):focus,
    > li > a:not(.btn):hover,
    > li > a:not(.btn):focus {
      background-color: transparent;
      border-radius: 3px;
      color: $info-color;
      @include opacity(1);
    }

    > .dropdown > a:hover .caret,
    > .dropdown > a:focus .caret {
      border-bottom-color: $info-color;
      border-top-color: $info-color;
    }

    > .open > a,
    > .open > a:hover,
    > .open > a:focus {
      background-color: transparent;
      color: $info-color;
    }

    .navbar-toggle:hover,
    .navbar-toggle:focus {
      background-color: transparent;
    }
  }

  &:not(.navbar-transparent) .btn-neutral,
  &:not(.navbar-transparent) .btn-neutral:hover,
  &:not(.navbar-transparent) .btn-neutral:active {
    color: $dark-gray;
  }
}

/*      Navbar with icons            */

.navbar-icons {
  &.navbar .navbar-brand {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .navbar-nav {
    > li > a {
      text-align: center;
      padding: $navbar-padding-a-icons;
      margin: $navbar-margin-a-icons;
    }

    [class^="pe"] {
      font-size: 30px;
      position: relative;
    }
    p {
      margin: 3px 0 0;
    }
  }
}

.navbar-form {
  @include box-shadow(none);
  .form-control {
    @include input-size(
      $padding-base-vertical,
      $padding-base-horizontal - 10,
      $height-base
    );

    border-radius: 0;
    border: 0;
    font-size: $font-size-navbar;
    line-height: $line-height-general;
    color: $light-gray;
  }
  .navbar-transparent & .form-control,
  [class*="navbar-ct"] & .form-control,
  .navbar-transparent & .input-group-addon,
  [class*="navbar-ct"] & .input-group-addon {
    color: $white-color;
    border: $none;
    background-color: rgba(255, 255, 255, 0.13);
  }

  [class*="navbar-ct"] & .form-control:focus,
  [class*="navbar-ct"] & .input-group-focus .input-group-addon {
    background-color: rgba(255, 255, 255, 0.23);
    color: $white-color;
  }

  [class*="navbar-ct"] & {
    .form-control:-moz-placeholder {
      @include placeholder($white-color, 1);
    }
    .form-control:-webkit-input-placeholder {
      @include placeholder($white-color, 1);
    }
    .form-control:-ms-input-placeholder {
      @include placeholder($white-color, 1);
    }
  }

  .input-group-addon {
    @include light-form();
    color: $light-gray;
    font-size: $font-size-navbar;
    padding: 0 7px;
    border-radius: $border-radius-base 0 0 $border-radius-base;
  }

  & .input-group-focus .input-group-addon,
  & .form-control:focus {
    color: $dark-gray;
  }
}

.navbar-ct-blue {
  @include navbar-color($blue-navbar);
}
.navbar-ct-azure {
  @include navbar-color($azure-navbar);
}
.navbar-ct-green {
  @include navbar-color($green-navbar);
}
.navbar-ct-orange {
  @include navbar-color($orange-navbar);
}
.navbar-ct-red {
  @include navbar-color($red-navbar);
}

.navbar-transparent {
  padding-top: 15px;
  background-color: transparent;
  border-bottom: 1px solid transparent;
}

.navbar-toggle {
  margin-top: 19px;
  margin-bottom: 19px;
  border: $none;

  .icon-bar {
    background-color: $white-color;
  }
  .navbar-collapse,
  .navbar-form {
    border-color: transparent;
  }

  &.navbar-default .navbar-toggle:hover,
  &.navbar-default .navbar-toggle:focus {
    background-color: transparent;
  }
}
