//colors
$white: #ffffff;
$smokywhite: #eeeeee;
$ltgreen: #90ee90;
$green: #008000;
$ltgray: #d3d3d3;
$drkgray: #a9a9a9;
$cyan: #04FFF7;




.wt-slidecontainer {
    width: 100%;
    margin-top:45px;
  }
  
  .slider:-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top:45px;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    background: $ltgray;
    outline: none;  
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    
  }
  
  .slider-disabled:-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top:45px;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    background: $ltgray;
    outline: none;  
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    
  }

  .wt-slider:hover {
    opacity: 1;
  }
  .wt-slider-disabled {
    opacity: 1;
    background: $drkgray;
  }
  
  .wt-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $ltgray;
    border: solid;
    cursor: pointer;
  }
  
  .wt-slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: $ltgray;
    cursor: pointer;
  }
  
  .wt-slider-label {
    text-align:center;
    color: $ltgray;
    letter-spacing: 4em;
    
  }

  .wt-slider-disabled-label {
    text-align:center;
    color: $ltgray;
    letter-spacing: 4em;
    
  }
