


.react-datepicker__input-container input {
    width:100%;
    

}

.react-datepicker-wrapper{
width:100%;
background-color:white;
}


.full-width-datepicker {
    width: 100% !important;
  }

.date-picker-button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: white;
    color: black;
    border: none;
}