/* Ensure this is loaded after bootstrap or other conflicting CSS files */
.filter-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

#chart-view-filter .checkbox-container {
  margin-bottom: 10px;
  /* Adds space between checkboxes */
}

#chart-view-filter .checkbox-label {
  display: flex;
  align-items: center;
  color: black !important;
  /* Ensures text is black and applies !important to override any other styles */
  margin-right: 10px;
  /* Adds space to the right of the checkbox */
}

#chart-view-filter .checkbox-input {
  margin-right: 10px;
  /* Space after the checkbox before the label text */
}


