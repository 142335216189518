/* File Name: custom-light-bootstrap.css
    Author: Teri Salisbury
    Date: 10/23/2023
    Description: Contains custom css to turn app dark

    Hex codes for accent colors:
        neon pink: #FF10F0
        Neon Turquoise - #04FFF7
        Neon Purple - #BC13FE
        Neon Orange - #FF5F1F
        Neon Green - #39FF14
        Vivid Sky Blue - #12B8FF
        Electric Lime - #CCFF00
        Neon Yellow: #FFED00
 */


.search-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #04FFF7;
    width: 30px;
    cursor: pointer;
    padding-left: 10px;
}

.equip-name {
    font-size: medium;
    font-weight: bold;
    margin-bottom: 15px;
    color: #04FFF7;
    text-transform: uppercase;
}

.text-primary,
.text-primary:hover {
    color: #0096FF !important;
    text-transform: uppercase;
    font-size: medium;
    font-weight: bold;
    line-height: 1;
}

.text-muted {
    color: #ffffff;
    font-size: small;
    line-height: 1;
}

.col-xs-4 {
    width: 33.33333333%;
    line-height: 1;
}

col-xs-5 {
    width: 41.66666667%;
    padding-left: 50px;
}

.nav-tabs {
    border-bottom: 1px solid #04FFF7;
    font-size: larger;
    text-transform: uppercase;
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin-top: 10px;
    padding: 0;
}

.btn.btn-fill {
    color: #ffffff;
    background-color: #000;
    border-color: #04FFF7;
    filter: alpha(opacity=100);
}

/*new class*/
.btn.btn-fill-global {
    padding: 4px;
    height: 58px;
    line-height: 1.2;
    font-size: medium;
    font-weight: bold;
    /* color: #04FFF7; */
    color: #04FFF7;
}

.btn-success {
    /* border-color: #04FFF7; */
    border-color: #04FFF7;
    color: #000;
}

.h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small,
.h1 small,
.h2 small,
.h3 small,
.h4 small,
.h5 small,
.h6 small,
h1 .small,
h2 .small,
h3 .small,
h4 .small,
h5 .small,
h6 .small,
.h1 .small,
.h2 .small,
.h3 .small,
.h4 .small,
.h5 .small,
.h6 .small {
    color: #ffffff;
    font-weight: 300;
    line-height: 1.5;
}


.collapse.in {
    display: block;
    background-color: transparent;
    color: white;
    font-size: medium;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    /* color: #04FFF7; */
    color: #04FFF7;
    cursor: default;
    background-color: #000;
    /* border: 1px solid #04FFF7; */
    border: 1px solid #04FFF7;
    border-left: 1px solid #04FFF7;
    border-left: 1px solid #04FFF7;
    border-bottom-color: transparent;
    text-transform: uppercase;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    color: whitesmoke;
}

.nav-tabs {
    /* border-bottom: 1px solid #04FFF7; */
    border-bottom: 1px solid #04FFF7;
}

.panel .panel-heading.connected {
    background-color: #313430;
}

hr {
    border: none;
    /* Remove the default border */
    border-top: 2px solid #e6232e;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 0;
    width: 100%;
}

.needs-attention-heading {
    color: #e6232e !important;
    text-align: center;
    font-size: medium;
    font-weight: bold;
}

.needs-attention-data {
    color: #e6232e !important;
    margin-left: 20px;
    font-size: small;
    font-weight: normal;
}

.text-danger,
.text-danger:hover {
    color: #e6232e !important;
}


.text-online,
.text-primary:hover {
    color: #87cb16 !important;
    text-transform: uppercase;
    font-size: medium;
    font-weight: bold;
    line-height: 1;
}

.text-online {
    color: #87cb16 !important;
    text-transform: uppercase;
    font-size: medium;
    font-weight: bold;
    line-height: 1;
}

.text-connected {
    color: #0096FF !important;
    text-transform: uppercase;
    font-size: medium;
    font-weight: bold;
    line-height: 1;
}

.text-other {
    color: #ccc !important;
    text-transform: uppercase;
    font-size: medium;
    font-weight: bold;
    line-height: 1;
}

.text-offline,
.text-primary:hover {
    color: #ccc !important;
    text-transform: uppercase;
    font-size: medium;
    font-weight: bold;
    line-height: 1;
}

/*mustangs*/
.btn.btn-fill {
    /* color: #04FFF7; */
    color: #04FFF7;
    background-color: #000;
    /* border-color: #04FFF7; */
    border-color: #04FFF7;
    filter: alpha(opacity=100);
}

.btn {
    border-width: 1px;
    background-color: transparent;
    font-weight: bold;
    opacity: 0.8;
    filter: alpha(opacity=80);
    padding: 8px 16px;
    border-color: #444;
    color: #444;
    font-size: medium;
    text-transform: uppercase;
}

/* .panel .panel-heading.online {
    background-color: #313430;
    font-size: medium;
    color:#04FFF7;
    text-transform: uppercase;
} */

.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
    /* color: #04FFF7; */
    color: #04FFF7;
}

/* .h5, .h5 {

    font-size: medium;
    color: #04FFF7;
    font-weight:bold;
    margin-bottom: 15px;
} */
.pump-name {
    font-size: medium;
    /* color: #04FFF7; */
    color: #04FFF7;
    font-weight: bold;
    margin-bottom: 15px;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success.active:focus,
.btn-success.active:hover,
.open>.btn-success.dropdown-toggle {
    background-color: #000;
    /* color: #04FFF7; */
    color: #04FFF7;
    /* border-color: #04FFF7; */
    border-color: #04FFF7;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success:disabled,
.btn-success:disabled:hover,
.btn-success:disabled:focus,
.btn-success:disabled.focus,
.btn-success:disabled:active,
.btn-success:disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
    background-color: transparent;
    /* border-color: #04FFF7; */
    border-color: #04FFF7;
}

.btn-success.btn-fill {
    /* color: #04FFF7; */
    color: #04FFF7;
    background-color: #000;
    opacity: 1;
    filter: alpha(opacity=100);
}

.btn-success.btn-fill:hover,
.btn-success.btn-fill:focus,
.btn-success.btn-fill:active,
.btn-success.btn-fill.active,
.open>.btn-success.btn-fill.dropdown-toggle {
    background-color: #000;
    color: #ffffff;
}

.btn-success.btn-fill .caret {
    /* border-top-color: #04FFF7; */
    border-top-color: #04FFF7;
}

.btn-success .caret {
    border-top-color: #04FFF7;
}

.table>thead>tr>th {
    border-bottom-width: 1px;
    font-size: large;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: bold;
    padding-bottom: 5px;
}

.body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-weight: bold;
    color: #ffffff;
}


/*new class - id*/

.pump-img {
    vertical-align: middle;
    border: transparent;
    border-width: 0px 15px 0px 0px
}

.card {
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(63, 63, 68, 0.1);
    background-color: transparent;
    margin-bottom: 30px;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #ffffff;
}

.nav-open .navbar-default .navbar-toggle .icon-bar {
    background-color: white;

}

.tab-content>.active {
    display: block;
    background-color: black;
}

.login-title {
    margin: 0;
    color: #64CCC5 !important;
    font-weight: bold !important;
    font-size: xx-large;
    text-transform: uppercase;
}

.checkbox label,
.radio label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 24px;
    margin-bottom: 0;
    line-height: 20px;
    color: #04FFF7;
}

.login-container {
    padding-right: 35px;
    padding-left: 35px;
    margin-right: auto;
    margin-left: auto;

}

/* .login-border {
    margin-bottom: 15px;
    border-style: ridge;
    border-color: #04fff7;
} */

.card .category,
.card .form-group>label {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 0px;
    font-weight: bold;
}

.search-box {
    background-color: #ffffff;
    border: #04FFF7;
    border-style: inset;
    border-radius: 4px;
    color: #565656;
    padding: 8px 12px;
    height: 40px;
    box-shadow: none;
    width: 250px;
    border-width: thin;
    float: right;

}

search-box-style {
    width: 180px;
    height: 30px;
    font-size: medium;
    padding: 5px;
}

.device-description {
    font-weight: normal;
    color: #ffffff;
    font-size: 16px;
}


.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
    position: relative;
    min-height: 1px;
    padding-right: 1px;
    padding-left: 5px;
}

.panel-body {
    padding: 10px 0 0 0;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    box-shadow: inset 0 0px 0px 0px transparent !important;
    background-color: #000;
}

.card .content {
    padding: 5px;
}

.container-fluid {
    padding-right: 15px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.small,
small {
    font-size: 100%;
}

.detail-tab-heading {
    font-weight: bold;
    font-size: x-large;
    line-height: 3em;
    color: white;
    text-decoration: underline;
}

.detail-tab-details {

    font-size: large;
    color: white;
    line-height: 2em;
}

.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: 0px solid #ddd;
}



.wt-lights {
    background-size: cover;
    background: transparent;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e2e2e2', endColorstr='#fefefe', GradientType=0);
    /* IE6-9 */
    /* padding: 20px; */

}

.led-box {
    height: 30px;
    width: 33%;
    margin: 10px 0;
    float: left;
}

.led-box p {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    margin: 1em;
}

.led-red {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    background-color: #F00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;

}

.led-green {
    margin: 0 auto;
    width: 24px;
    height: 24px;
    background-color: #ABFF00;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px;
}

.dashboard-filter-btn {
    color: black;
    font-family: inherit;
    font-size: 12px;
    line-height: inherit;
    background: #04FFF7 !important;
    font-weight: bold;
    border: solid;
    border-color: #04FFF7 !important;
    margin: 9px 5px 5px 10px;
    border-style: ridge;
    border-width: 3px;
    padding: 3px 0 2px 0;
    float: left;
}

.dashboard-reset-btn {
    color: black;
    font-family: inherit;
    font-size: 12px !important;
    line-height: inherit;
    background: #04FFF7 !important;
    font-weight: bold;
    border: solid;
    border-color: #04FFF7 !important;
    margin: 9px 5px 5px 5px;
    border-style: ridge;
    border-width: 3px;
    padding: 4px 0;
    float: right;

}

.equipment-btns {
    padding: 4px;
    margin-right: 5px;
    margin-left: 5px;
    height: 58px;
    line-height: 1.2;
    font-size: medium;
    font-weight: bold;
    color: #04FFF7;
    background-color: black;
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background: #04FFF7;
    color: black;
    border: none;
}

.slider-container {
    display: flex;
    align-items: center;
    font-size: small;

}

.slidecontainer {
    position: relative;
    /* For absolute positioning of the slider label */
    flex-grow: 1;
    /* Allows the slider to grow and fill space */
    margin: 0 0px;
    /* Adds some space between the images and the slider */
    color: #04FFF7;
    font-size: small;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #04FFF7;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider:disabled {
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: gray;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #04FFF7;
    cursor: pointer;
}

.slider-label {
    text-align: center;
    color: #04FFF7;
    letter-spacing: 1.75em;
    margin-left: 25px;
    margin-top: 25px;

}

.slider-label-disabled {
    text-align: center;
    color: gray;
    letter-spacing: 1.75em;
    margin-left: 25px;
    margin-top: 25px;

}

.wt-settings-btn-box {
    display: flex;
    justify-content: space-around;

}



.two-line-button {

    color: #04FFF7;
    font-size: small;
    font-weight: bold;
    margin-bottom: 25px;
    margin-left: 0px;
    margin-top: 5px;
    display: block;
    text-align: center;
    width: 135px;
    height: 65px;
    border-radius: 15%;
    border: solid;
    border-color: #04FFF7;
    border-width: 2px;
    background-color: black;
    text-decoration: none;
    box-shadow: 2px 2px 5px white;

}

/* .two-line-button:hover {
    background-color: black;
    color: #ccf7f5;
    border-color: #ccf7f5;
    box-shadow: 2px 2px 5px white;

} */



.two-line-button.clicked {
    background-color: #000;
    color: white;
    border-color: white;
    box-shadow: 2px 2px 9px Green;

}


.two-line-button.active {
    background-color: rgb(21, 166, 16);
    color: white;
    border-color: white;
    box-shadow: 2px 2px 5px white;

}

.two-line-button:disabled {
    background-color: black;
    color: gray;
    border-color: gray;
    box-shadow: 2px 2px 5px white;
    margin-top: 5px;
    margin-bottom: 25px;

}

/* Second line style */
.two-line-button span {
    display: block;
    font-size: x-large;

}

.wt-disengage-center {
    display: flex;
    margin: auto;
    justify-content: space-around;
    width: 50%;
    padding: 5px;
}

.image-disengage-btn {
    margin-top: 20px;
    margin-bottom: 10px;
    background: none;
    border: none;
    padding: 0;
    box-shadow: 2px 2px 5px lightgray;

}

.image-disengage-btn:hover {
    box-shadow: 4px 4px 10px #ffffff;
}


.image-btn img {
    display: block;
    /* to remove extra space below image */
}

/* .wt-disengage-engine-btn {

    color: red;
    font-size: large;
    font-weight: bold;
    font-stretch: ultra-expanded;
    margin-bottom: 25px;
    margin-left: 0px;
    margin-top: 15px;
    display: block;
    text-align: center;
    width: 200px;
    height: 45px;
    border-radius: 15%;
    border: solid;
    border-color: red;
    border-width: 2px;
    background-color: white;
    text-decoration: none;
    box-shadow: 2px 2px 5px gray;

}

.wt-disengage-engine-btn:hover {
    background-color: rgb(143, 235, 235);
    color: red;
    border-color: red;
    box-shadow: 2px 2px 5px black;

} */
.wt-disengage-engine-disabled-btn {

    color: black;
    font-size: large;
    font-weight: bold;
    font-stretch: ultra-expanded;
    margin-bottom: 25px;
    margin-left: 0px;
    margin-top: 15px;
    display: block;
    text-align: center;
    width: 100%;
    height: 45px;
    border-radius: 15%;
    border: solid;
    border-color: gray;
    border-width: 2px;
    background-color: darkgray;
    text-decoration: none;
    box-shadow: 2px 2px 5px gray;

}

.rabbit-image {
    /* Adjust the size of the images if they are too large */
    width: 30px;
    /* Example size, adjust as needed */
    height: auto;
    padding-left: 0px;
}

.turtle-image {
    /* Adjust the size of the images if they are too large */
    width: 30px;
    /* Example size, adjust as needed */
    height: auto;
    padding-right: 5px;
}

.limit-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    color: #04FFF7;
    text-shadow: 5px 5px 10px black;
}


/* menu modal css */
.menu-modal .modal-dialog {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    width: 100%;
    max-height: 50%;
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
}

.menu-modal.show .modal-dialog {
    transform: translateY(0);
}

/* App.css */
.modal-section {
    flex: 1;
    margin-bottom: 20px;
}

.modal-section h5 {
    margin-bottom: 15px;
    color: black;
}

.modal-sections-container {
    display: flex;
    justify-content: space-around;
}

/* @media (max-width: 768px) {
    .modal-sections-container {
      flex-direction: column;
    }
  } */

/* .modal-apply-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: 1px solid #67BD76;
    padding: 4px 10px;
    border-radius: 6px;
    background: none;
    font-size: 14px;
    font-weight: bold;
    color: #67BD76;
    cursor: pointer;
} */

.checkbox-group {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.checkbox-group label {
    margin-left: 5px;
    margin-top: 10px;
    font-size: 14px;
    color: black;
}

.checkbox-group input {
    margin-left: 5px;
}

.small-checkbox {
    width: 20px;
    /* Adjust the width as needed */
    height: 20px;
    /* Adjust the height as needed */
}



.modal-apply-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: 2px groove black;
    padding: 4px 10px;
    border-radius: 6px;
    background: none;
    font-size: 14px;
    font-weight: bold;
    color: black;
    cursor: pointer;
    box-shadow: 1px 1px 4px gray;

}

.modal-cancel-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: 2px solid #555;
    padding: 4px 10px;
    border-radius: 6px;
    background: none;
    font-size: 14px;
    font-weight: bold;
    color: #333;
    cursor: pointer;
    box-shadow: 1px 1px 4px gray;
    margin-right: 80px;

}

.modal-body {
    position: relative;
    padding: 15px;
    background-color: lightgray;
}

.left-paragraph {
    float: left;
    color: white;

}

.right-paragraph {
    float: right;

}

.middle-paragraph {
    text-align: center;

}


.data-date-time {
    font-size: large;
    font-weight: bold;
    margin-bottom: 15px;
    color: white;
    text-transform: uppercase;
}

.white-text {
    color: white;
    font-size: small;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    color: #04FFF7;
}

.modal-dialog > .modal-content {
    background-color: #666;
}

.modal-dialog .modal-body label {
    color: black;
}

.discharge-info {
    margin-left: 20px;
    font-size: small;
    color: white;
    font-weight: bold;
    margin-top: 5px;

}

.value-cyan {
    color: #04FFF7;
}


.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + 1.7rem / 2);
    overflow-y: scroll;
    padding-right: 0;
    padding-left: 0;
    width: 120% !important;
    font-size: small;
    box-sizing: content-box;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    margin-top: 0;
    color: #000;
    font-weight: bold;
    font-size: medium !important;
}

.react-datepicker__month-container {
    float: left;
    width: fit-content !important;
}

.datepicker-date-flds {
    background-color: white;
    color: black;

}

.react-datepicker-wrapper {
    display: inline-block;
    background-color: white;
    padding: 0;
    border: 1px;
    border-color: black;
    color: black;
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block;
    width: 100%;
    background-color: white;
    background: white;
}

.datatypes-btn {
    color: black;
    font-family: inherit;
    font-size: 12px;
    line-height: inherit;
    background: white !important;
    font-weight: bold;
    border: solid;
    border-color: white !important;
    border-style: ridge;
    border-width: 3px;
    padding: 4px 3px 2px 3px;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;


}

.data-types-container {
    margin-top: 20px;
    /* Adjust as needed */
}

.data-types-selection {
    /* Add any styles you want for the data type checkboxes */
}

.date-time-selection {
    margin-top: 20px;
    /* Provides space between the data types and date pickers */
    display: flex;
    flex-direction: column;
    /* Stack the date pickers vertically */
}

.date-picker-container {
    margin-bottom: 10px;
    /* Space between the date pickers */
}

.data-unavailable {
    color: white;
}

.needs-attn {
    color: red;
}

.data-display {
    color: white
}




.data-type-checkbox.red input[type="checkbox"]:checked+.custom-checkbox {
    background-color: red;
    border-color: red;
}

.data-type-checkbox.blue input[type="checkbox"]:checked+.custom-checkbox {
    background-color: blue;
    border-color: blue;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    display: inline-block;
    position: relative;
}

.custom-checkbox:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    width: 8px;
    height: 8px;
    background-color: white;
    display: none;
}

.data-type-checkbox input[type="checkbox"]:checked+.custom-checkbox:after {
    display: block;
}