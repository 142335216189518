.checkboxA {
    margin-bottom: 12px;
  }
  
  .checkboxA label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    padding-left: 24px;
    margin-bottom: 0;
    line-height: 20px;
  }
  
  .checkboxA label::before,
  .checkboxA label::after {
    font-family: "FontAwesome";
    content: "\f096";
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    cursor: pointer;
    line-height: 20px;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 0px;
    color: #dddddd;
    transition: color 0.2s linear;
    padding: 1px;
  }
  
  .checkboxA label::after {
    content: "";
    text-align: center;
    opacity: 1;
    left: 0;
    color: #dddddd;
  }
  
  .checkboxA input[type="checkbox"] {
    opacity: 0;
    margin-left: 0;
  }
  
  .checkboxA input[type="checkbox"]:checked+label::after {
    font-family: "FontAwesome";
    content: "\f046";
  }
  
  .checkboxA.red input[type="checkbox"]:checked+label::after {
    /* color: #23ccef; */
    color: #87CB15;
  }

  .checkboxA.blue input[type="checkbox"]:checked+label::after {
    /* color: #23ccef; */
    color: #168dc9;
  }
  
  .checkboxA input[type="checkbox"]:checked+label::before {
    opacity: 0;
  }
  
  .checkboxA input[type="checkbox"]:disabled+label,
  .checkboxA input[type="checkbox"]:disabled:checked+label::after {
    color: #dddddd;
  }
  
  .checkboxA input[type="checkbox"]:disabled+label::before,
  .checkboxA input[type="checkbox"]:disabled+label::after {
    cursor: not-allowed;
  }
  
  .checkboxA input[type="checkbox"]:disabled+label {
    cursor: not-allowed;
  }
  
  .checkboxA.checkbox-circle label::before {
    border-radius: 50%;
  }
  
  .checkboxA.checkbox-inline {
    padding-left: 0;
  }