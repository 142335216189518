//Colors
$green: #2ecc71;
$red: #e74c3c;
$drkred: #BC131F;
$blue: #619be6;
$drkblue: #3482db;
$yellow: #f1c40f;
$purple: #8e44ad;
$turquoise: #1abc9c;
$gray:#B9B7B7;
$drkgreen: #078D40;
$drkgray: rgb(94, 92, 92);
$white: #ffffff;


.wt-center {
  display: flex;
  margin: auto;
  justify-content: space-around;
  width: 50%;
  padding: 0px;
}

// Basic Button Style
.wt-btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 8px solid $red;
  border-style: inset;
  border-radius:50%;
  width: 150px;
  height: 150px;
  color: $red;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: x-large;
  font-weight: bold;
  line-height: 47px;
  margin: 10px;
  padding-top: 2em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  }
}


//starting
.wt-starting-btn {
  //border-radius: 3em;
  border-color: $red;
  color: #fff;
  appearance: none;
  background-color: $yellow;
  border: 10px solid $red;
  border-style: inset;
  border-radius:50%;
  width: 200px;
  height: 200px;
  font-size: xx-large;
  font-weight: bold;
  line-height: 65px;
  padding-top: 55px;
  //padding-left:25px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #fff;
  margin-top: 30%;
  text-shadow: 2px 2px #7c7878;
  //color: $blue;
  background: {
    image: linear-gradient(to top left, 
      
      transparentize($yellow, 0.0),
      transparentize($yellow, 0.0) 5%,
      $red 5%, 
      $red 10%,
      transparentize($yellow, 0.0) 10%,
      transparentize($yellow, 0.0) 15%,
      $red 15%, 
      $red 20%,
      transparentize($yellow, 0.0) 20%,
      transparentize($yellow, 0.0) 25%,
      $red 25%, 
      $red 30%,
      transparentize($yellow, 0.0) 30%,
      transparentize($yellow, 0.0) 35%,
      $red 35%, 
      $red 40%,
      transparentize($yellow, 0.0) 40%,
      transparentize($yellow, 0.0) 45%,
      $red 45%, 
      $red 50%,
      transparentize($yellow, 0.0) 50%,
      transparentize($yellow, 0.0) 55%,
      $red 55%, 
      $red 60%,
      transparentize($yellow, 0.0) 60%,
      transparentize($yellow, 0.0) 65%,
      $red 65%, 
      $red 70%,
      transparentize($yellow, 0.0) 70%,
      transparentize($yellow, 0.0) 75%,
      $red 75%, 
      $red 80%,
      transparentize($yellow, 0.0) 80%,
      transparentize($yellow, 0.0) 85%,
      $red 85%, 
      $red 90%,
      transparentize($yellow, 0.0) 90%,
      transparentize($yellow, 0.0) 95%,
      $red 95%, 
      $red 100%);
    position: 0 0;
    size: 100%;
  }
  transition: background 300ms ease-in-out;
  
  &:hover {
    background-position: 100px;
  }
}

//starting
.wt-stopping-btn {
  //border-radius: 3em;
  border-color: $white;
  color: #fff;
  appearance: none;
  background-color: $drkgray;
  border: 10px solid $gray;
  border-style: inset;
  border-radius:50%;
  width: 200px;
  height: 200px;
  font-size: xx-large;
  font-weight: bold;
  line-height: 65px;
  padding-top: 55px;
  //padding-left:25px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #fff;
  margin-top: 30%;
  text-shadow: 2px 2px #7c7878;
  //color: $blue;
  background: {
    image: linear-gradient(to top left, 
      
      transparentize($gray, 0.0),
      transparentize($gray, 0.0) 5%,
      $drkgray 5%, 
      $drkgray 10%,
      transparentize($gray, 0.0) 10%,
      transparentize($gray, 0.0) 15%,
      $drkgray 15%, 
      $drkgray 20%,
      transparentize($gray, 0.0) 20%,
      transparentize($gray, 0.0) 25%,
      $drkgray 25%, 
      $drkgray 30%,
      transparentize($gray, 0.0) 30%,
      transparentize($gray, 0.0) 35%,
      $drkgray 35%, 
      $drkgray 40%,
      transparentize($gray, 0.0) 40%,
      transparentize($gray, 0.0) 45%,
      $drkgray 45%, 
      $drkgray 50%,
      transparentize($gray, 0.0) 50%,
      transparentize($gray, 0.0) 55%,
      $drkgray 55%, 
      $drkgray 60%,
      transparentize($gray, 0.0) 60%,
      transparentize($gray, 0.0) 65%,
      $drkgray 65%, 
      $drkgray 70%,
      transparentize($gray, 0.0) 70%,
      transparentize($gray, 0.0) 75%,
      $drkgray 75%, 
      $drkgray 80%,
      transparentize($gray, 0.0) 80%,
      transparentize($gray, 0.0) 85%,
      $drkgray 85%, 
      $drkgray 90%,
      transparentize($gray, 0.0) 90%,
      transparentize($gray, 0.0) 95%,
      $drkgray 95%, 
      $drkgray 100%);
    position: 0 0;
    size: 100%;
  }
 // transition: background 300ms ease-in-out;
  
  // &:hover {
  //   background-position: 100px;
  // }
}

//enable
.wt-enable-btn {

 // box-sizing: border-box;
  appearance: none;
  background-color: $blue;
  border: 10px solid $drkblue;
  border-style: inset;
  border-radius:50%;
  width: 200px;
  height: 200px;
  color: $blue;
  text-shadow: 2px 2px #7c7878;
  font-size: xx-large;
  font-weight: bold;
  line-height: 65px;
  padding-top: 50px;
  //padding-left:25px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #fff;
  box-shadow: 0 0 40px 40px $blue inset, 0 0 0 0 $blue;
  transition: all 150ms ease-in-out;
  //margin-left: 10%;
  margin-top: 30%;
  
  
   &:hover {
     box-shadow: 0 0 10px 0 $drkblue inset, 0 0 10px 4px $blue;
   }

   wt-enable-btn:disabled {
   appearance: none;
   background-color: $gray;
   border: 10px solid $drkgray;
   color: $gray;
   text-shadow: 2px 2px #7c7878;
   box-shadow: 0 0 40px 40px $gray inset, 0 0 0 0 $gray;
   margin-top: 30%;
   margin-bottom: 50px;
  }
}

.wt-enable-disabled-btn {

  // box-sizing: border-box;
   appearance: none;
   background-color: $gray;
   border: 10px solid $drkgray;
   border-style: inset;
   border-radius:50%;
   width: 200px;
   height: 200px;
   color: $blue;
   text-shadow: 2px 2px #7c7878;
   //cursor: pointer;
   //display: flex;
   //float: none;
   //align-self: center;
   font-size: xx-large;
   font-weight: bold;
   line-height: 65px;
   padding-top: 50px;
   //padding-left:25px;
   text-decoration: none;
   text-align: center;
   text-transform: uppercase;
   font-family: 'Montserrat', sans-serif;
   font-weight: 800;
   color: black;
   box-shadow: 0 0 40px 40px $gray inset, 0 0 0 0 $gray;
   transition: all 150ms ease-in-out;
   margin-bottom: 50px;
   margin-top: 30%;

 }

//start
.wt-start-btn {
  background-color: $drkgreen;
  border: 10px solid $green;
  border-style: inset;
  border-radius:50%;
  width: 200px;
  height: 200px;
  font-size: xx-large;
  text-align: center;
 //  padding-left: 1em;
  border-color: $green;
  font-weight: bold;
  line-height: 65px;
  padding-top: 55px;
  text-shadow: 2px 2px #7c7878;
  color: #fff;
  font-weight: 800;
  margin-top: 30%;  
  box-shadow: 0 0 40px 40px $green inset, 0 0 0 0 $green;
  transition: all 150ms ease-in-out;
  
   &:hover {
     box-shadow: 0 0 10px 0 $drkgreen inset, 0 0 10px 4px $green;
   }
}
//stop
.wt-stop-btn {
  appearance: none;
  background-color: $red;
  border: 10px solid $drkred;
  border-style: inset;
  border-radius:50%;
  width: 200px;
  height: 200px;
  color: $red;
  text-shadow: 2px 2px #7c7878;
  font-size: xx-large;
  font-weight: bold;
  line-height: 65px;
  padding-top: 55px;
  //padding-left:25px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  color: #fff;
  transition: all 150ms ease-in-out;
  //margin-left: 10%;
  margin-top: 30%;
  box-shadow: 0 0 40px 40px $red inset, 0 0 0 0 $drkred;
  transition: all 150ms ease-in-out;
  
   &:hover {
     box-shadow: 0 0 10px 0 $drkred inset, 0 0 10px 4px $red;
   }
}
